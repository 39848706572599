<template>
  <svg
    :width="size"
    :height="size"
    :class="{ checked }"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      stroke="currentColor"
      stroke-width="2"
    />
    <circle
      class="fill"
      cx="12"
      cy="12"
      r="5"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-select',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
svg {
  &.checked > circle.fill,
  &:hover > circle.fill {
    opacity: 1;
  }
}
.fill {
  opacity: 0;
  transition: opacity 90ms ease-out;
}
</style>
